import {defaultState, IResultsInit} from "./models";
import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";


export const fixturesReducer = (state: IResultsInit = defaultState, action: any): IResultsInit => {
    switch (action.type) {
        case ActionCreatorsTypes.SET_RESULTS_PAGE:
            return {
                ...state,
                pageHeader: action.payload.pageHeader,
                pageData: action.payload.pageData,
                isLoading: false
            }

        case ActionCreatorsTypes.GET_FILTERED_FIXTURES: {
            return {...state, isLoading: true};
        }

        case ActionCreatorsTypes.SET_FILTERED_FIXTURES: {
            return {...state, pageData: action.payload, isLoading: false};
        }
        case ActionCreatorsTypes.SET_SELECTED_SERIES: {
            return {...state, selectedSeries: action.payload}
        }
        case ActionCreatorsTypes.SET_SELECTED_WEEK: {
            return {...state, selectedWeek: action.payload}
        }
        case ActionCreatorsTypes.SET_SELECTED_GROUP: {
            return {...state, selectedGroup: action.payload}
        }

        default:
            return state
    }
}
