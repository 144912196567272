export enum ActionCreatorsTypes {
    GET_NEWS = "GET_NEWS",
    SET_NEWS = "SET_NEWS",
    NEWS_LOADER = "NEWS_LOADER",
    GET_RESULTS_PAGE = "GET_RESULTS_PAGE",
    SET_RESULTS_PAGE = "SET_RESULTS_PAGE",
    GET_FILTERED_FIXTURES = "GET_FILTERED_FIXTURES",
    SET_FILTERED_FIXTURES = "SET_FILTERED_FIXTURES",
    SET_SELECTED_SERIES = "SET_SELECTED_SERIES",
    SET_SELECTED_WEEK = "SET_SELECTED_WEEK",
    SET_SELECTED_GROUP = "SET_SELECTED_GROUP",
}
