import React from 'react';
import {IHonoursData} from "./Honours";

interface IHonorWidgetProps {
    data: IHonoursData;
    id: number;
    visibility: string;
    visibilityHandler: (id: number) => void;
}

const HonorWidget = (props: IHonorWidgetProps) => {
    const {data, id, visibility, visibilityHandler} = props;
    return (
        <div className="col-12 col-xl-6 honours-wrapper">
            <div className="item wow fadeInLeft">
                <div className="top">
                    <div className="position">
                        {data.phaseNumber}
                    </div>
                    <div className="name">
                        {data.phaseWinner}
                        <i
                            id={String(id)}
                            onClick={() => visibilityHandler(id)}
                            className="fas fa-caret-down"
                            aria-hidden="true"/>
                    </div>
                </div>
                <div className="contents">
                    <img
                        src={`data:${data.winnerPicture?.contentType};base64,${data.winnerPicture?.fileContents}`}
                        alt="Lisa Ashton"/>
                    <div
                        className={visibility}>
                        <div className="honours-other-players">
                            <span className="number">2<small>nd</small></span>
                            <div className='dropdown-players-item'>{data.phase2ndPlace}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HonorWidget;
