import React, {useEffect, useState} from 'react';
import '../../assets/css/global.scss'
import home from '../../assets/img/home.png'
import iphoneBG from '../../assets/img/iphoneBG.png'
import topBG from '../../assets/img/announcement/top.svg'
import bottomBG from '../../assets/img/announcement/bottom.svg'
import {TwitterTweetEmbed} from "react-twitter-embed";
import {Link, useHistory} from "react-router-dom";
import {client} from "../../services/api.service";
import moment from "moment/moment";
import mssHomePageVideo from "../../assets/video/mssHomePageVideo.mp4";
import {IMssNews} from "../../saga/mss-news/models";
import Loader from "../../components/Loader/Loader";
import {removeNameSeparator} from "../../helpers/rmNameSeparator";

export interface IHomeFixture {
    gameId: number,
    fixture: string,
    playerHome: string,
    playerAway: string,
    scorePlayerHome: number,
    scorePlayerAway: number
}

export interface IHomePageInfo {
    latestScores: IHomeFixture[],
    upcomingFixtures: IHomeFixture[],
    lastNews: IMssNews
}

interface IHomePageTweets {
    tweetIds: string[]
}

const HomePage: React.FC = () => {
    const history = useHistory()
    const [homePageInfo, setHomePageInfo] = useState<IHomePageInfo>({} as IHomePageInfo)
    const [isLoading, setIsLoading] = useState(false)
    const [lastTweets, setLastTweets] = useState<IHomePageTweets>({} as IHomePageTweets);


    const getLatestInfo = async () => {
        try {
            setIsLoading(true)
            const {data} = await client.get("/api/MssWeb/home-init")
            setHomePageInfo(data)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }
    const getLastTweets = async () => {
        try {
            setIsLoading(true)
            const {data} = await client.get("/api/SocialMedia/last/ids")
            setLastTweets(data)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    };

    useEffect(() => {
        getLatestInfo()
        // getLastTweets()
    }, [])


    const moveToMatchStatistic = (fixtureId: number) => history.push(`match/statistics/${fixtureId}`);
    return (
        <div>
            <div id="homeHero">
                <img src={home} className="hideMobile2"
                     alt="Modus Super Series"/>
                <img src={iphoneBG} className="hideDesktop2"
                     alt="Modus Super Series"/>
            </div>


            <div className="newsTitle home">
                <div className="wrap">
                    <div className="sub">Introducing</div>
                    <h1>The Modus Super Series</h1>
                </div>
            </div>

            <div className="wrap">
                <div className="homeNav sequel">
                    <Link to={"/results"} className="first">Results/Fixtures</Link>
                    <Link to={"/news"}>Latest News</Link>
                    <Link to={"/about"}>About</Link>
                </div>
            </div>

            <div className="vidEmbed hideDesktop">
                <div className="video-container">
                    <video className={'video-wrapper'} controls={true} src={mssHomePageVideo}></video>
                </div>
            </div>

            <div className="announcement home">
                <img src={topBG} className="top"/>
                <img src={bottomBG} className="bottom"/>
                <div className="wrap">
                    <div className="vidEmbed hideMobile">
                        <div className="video-container">
                            <div>
                                <video className={'video-wrapper'} controls={true} src={mssHomePageVideo}></video>

                            </div>
                        </div>
                    </div>

                    <div className="sub sequel">MODUS SUPER SERIES</div>
                    <h3>
                        BRINGING YOU LIVE DARTING ENTERTAINMENT SIX DAYS A WEEK.
                    </h3>
                </div>
            </div>

            <div className="fixtureBlock">
                {
                    homePageInfo?.upcomingFixtures ?
                        <div className="wrap">
                            <div className="row">
                                <div className="col-12 col-xl-7">
                                    <div className="subTitle sequel">
                                        Latest Scores
                                    </div>
                                    <div className="row sequel">
                                        {
                                            homePageInfo?.latestScores?.map((fixture, index) => {
                                                return (
                                                    <div
                                                        onClick={() => moveToMatchStatistic(fixture.gameId)}
                                                        key={fixture.gameId}
                                                        className="col-12 col-xl-6"
                                                    >
                                                        <div className="fixture">
                                                            <div className="title">
                                                                Match {index + 1}
                                                            </div>
                                                            <div className="item">
                                                                <span
                                                                    className="score">{fixture?.scorePlayerHome}</span>
                                                                <span
                                                                    className="name">{removeNameSeparator(fixture.playerHome)}</span>
                                                            </div>
                                                            <div className="item">
                                                                <span
                                                                    className="score">{fixture?.scorePlayerAway}</span>
                                                                <span
                                                                    className="name">{removeNameSeparator(fixture?.playerAway)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div>
                                <div className="col-12 sequel col-xl-5">
                                    <div className="subTitle">Upcoming Fixtures</div>
                                    {
                                        homePageInfo?.upcomingFixtures?.map((fixture) => {
                                            return (
                                                <div
                                                    onClick={() => moveToMatchStatistic(fixture.gameId)}
                                                    key={fixture.gameId}
                                                    className="fixtureUpcoming wow fadeIn"
                                                >
                                        <span
                                            className="time">{moment.utc(fixture.fixture).local().format("LT")}</span>
                                                    <span
                                                        className="names">
                                                       {removeNameSeparator(fixture.playerHome)}
                                                        <small>vs</small>
                                                       {removeNameSeparator(fixture.playerAway)}
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <Loader/>
                }
            </div>
            <div className="twitter-slider">
                {!isLoading && lastTweets?.tweetIds?.map((tweetId) => {
                    return (
                        <div className="tweetItem" key={tweetId}>
                            <TwitterTweetEmbed
                                tweetId={tweetId}
                                options={{height: 500}}
                            />
                        </div>
                    )
                })}
            </div>
            <div className="highlightedArticle" style={{paddingTop: 0}}>
                {
                    homePageInfo?.lastNews?.id
                    ?
                    <div className="row">
                        <div className="d-flex justify-content-center align-items-center flex-wrap">
                            <div className="col-md-6 align-items-end">
                                <img
                                    src={`data:image/png;base64,${homePageInfo?.lastNews?.images[0]?.image}`}
                                    alt={'winner-png'}/>
                            </div>
                            <div className="col-md-6 highlightedArticle-pad">
                                <div className="subTitle sequel">News</div>
                                <h4>{homePageInfo?.lastNews?.title}</h4>
                                <Link to={`/singleNews/${homePageInfo?.lastNews?.id}`} className="sequel">
                                    Find Out More
                                </Link>
                            </div>
                        </div>
                    </div>
                        :
                        <Loader/>
                }
            </div>
        </div>
    );
};

export default HomePage;
