import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import topBG from '../../assets/img/announcement/top.svg'
import bottomBG from '../../assets/img/announcement/bottom.svg'
import {useDispatch} from "react-redux";
import {Dropdown, DropdownProps} from "semantic-ui-react";
import moment from "moment/moment";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {useHistory} from "react-router-dom";
import './style.scss';
import './style-media.scss';
import {IFixture, ITableRow, IWeek} from "../../saga/fixtures/models";
import Loader from "../../components/Loader/Loader";
import {removeNameSeparator} from "../../helpers/rmNameSeparator";
import {ActionsCreator} from "../../redux/actions/actionCreator";

interface IFixtureLabel {
    label: string;
    labelClass: string;
}

const Results = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const fixturesTables = useTypedSelector(state => state.fixtures);
    const {selectedWeek, selectedSeries, selectedGroup} = useTypedSelector(state => state.fixtures);
    const [week, setWeek] = useState<string>('');
    const [currentTableGroup, setCurrentTableGroup] = useState(1);
    const [currentFixture, setCurrentFixture] = useState([] as IFixture[]);
    const [currentTable, setCurrentTable] = useState([] as ITableRow[]);
    const [currentFinalTable, setCurrentFinalTable] = useState([] as ITableRow[]);

    const moveToMatchStatistic = (fixtureId: number) => history.push(`match/statistics/${fixtureId}`);

    const phaseOptions = useMemo(() => {
        return fixturesTables?.pageHeader?.phases?.map(phase => ({
            key: phase.id,
            text: phase.name,
            value: phase.name
        })).reverse()
    }, [fixturesTables.pageHeader.phases])
    const weekOptions = useMemo(() => {
        const currentPhase = fixturesTables?.pageHeader?.phases?.find(phase => phase.id === selectedSeries)
        if (currentPhase) {
            return currentPhase.weeks.map((week: IWeek) => ({
                key: week.id,
                text: `W ${week.sequential} - ${moment(week.from).format("MMMM Do")}`,
                value: `W ${week.sequential} - ${moment(week.from).format("MMMM Do")}`
            }))
        } else {
            return []
        }
    }, [selectedWeek, selectedSeries, fixturesTables.pageHeader.currentPhaseId])

    const changeCurrentPhase = (e: React.SyntheticEvent, {value}: DropdownProps) => {
        const currentPhase = fixturesTables.pageHeader.phases.find((phase) => phase.name === value);
        if (currentPhase) {
            dispatch(ActionsCreator.setSelectedSeries(currentPhase.id))
            dispatch(ActionsCreator.setSelectedWeek(currentPhase.weeks[0].id))
            setWeek(`W ${currentPhase.weeks[0].sequential} - ${moment(currentPhase.weeks[0].from).format("MMMM Do")}`)

            const payload = {
                weekId: currentPhase.weeks[0].id,
                phaseId: currentPhase.id,
                yearId: 2023
            }
            dispatch(ActionsCreator.getFilteredFixtures(payload))
        }
    };
    const changeCurrentWeek = (e: React.SyntheticEvent, {value}: DropdownProps) => {
        setWeek(value as string)
        const currentWeek = weekOptions.find((week) => week.value === value);
        if (currentWeek) {
            dispatch(ActionsCreator.setSelectedWeek(currentWeek.key));
            const payload = {
                weekId: currentWeek.key,
                phaseId: selectedSeries,
                yearId: 2023
            }
            dispatch(ActionsCreator.getFilteredFixtures(payload))
        }
    };
    const setCurrentGroup = (id: number) => dispatch(ActionsCreator.setSelectedGroup(id));

    const getTypeOfFixture = (fixture: IFixture) => {
        if (!fixture.scorePlayerAway && !fixture.scorePlayerHome) {
            return "fixture pending"
        } else {
            return "fixture"
        }
    }

    const formatPlayerScore = (fixture: IFixture, score: number) => {
        if (!fixture.scorePlayerAway && !fixture.scorePlayerHome) {
            return "?"
        } else return score
    };

    const filterFinalTablesByGroup = (): ITableRow[] => {
        if (fixturesTables?.pageData?.mssFixturesTables?.data) {
            const [group1] = fixturesTables?.pageData?.mssFixturesTables?.data
                .filter((group) => group.groupName === 'FinalGroup1');
            const [group2] = fixturesTables?.pageData?.mssFixturesTables?.data
                .filter((group) => group.groupName === 'FinalGroup2');
            return currentTableGroup === 1 ? group1?.lines : group2?.lines
        }
        return []
    }

    const filterFixturesTables = (): void => {
        switch (selectedGroup) {
            case 1:
                if (!fixturesTables?.pageData?.mssFixturesTables?.data[0]?.fixtures) {
                    setCurrentTable([])
                    return setCurrentFixture([])
                }
                setCurrentTable(fixturesTables.pageData?.mssFixturesTables?.data[0]?.lines)
                return setCurrentFixture(fixturesTables.pageData.mssFixturesTables.data[0]?.fixtures)

            case 2:
                if (!fixturesTables.pageData?.mssFixturesTables?.data[1]?.fixtures) {
                    setCurrentTable([])
                    return setCurrentFixture([])
                }
                setCurrentTable(fixturesTables.pageData?.mssFixturesTables?.data[1]?.lines)
                return setCurrentFixture(fixturesTables.pageData.mssFixturesTables.data[1]?.fixtures)
            case 3:
                if (!fixturesTables.pageData?.mssFixturesTables?.data[2]?.fixtures) {
                    setCurrentTable([])
                    return setCurrentFixture([])
                }
                setCurrentTable(fixturesTables.pageData?.mssFixturesTables.data[2]?.lines)
                return setCurrentFixture(fixturesTables.pageData?.mssFixturesTables.data[2]?.fixtures)
            case 4:
                setCurrentFinalTable(filterFinalTablesByGroup())
                return setCurrentFixture(concatFinalFixtures())
        }
    }

    const generateTable = (item: ITableRow, index: number): ReactElement => {
        return (
            <div key={index + 1} className="row wow fadeIn">
                <div className="col-1">
                    <div className="stat white">
                        {index + 1}
                    </div>
                </div>
                <div className="col-4">
                    <div className="name">
                        {removeNameSeparator(item.player)}
                    </div>
                </div>
                <div className="col-1">
                    <div className="stat">
                        {item.p}
                    </div>
                </div>
                <div className="col-1">
                    <div className="stat">
                        {item.w}
                    </div>
                </div>
                <div className="col-1">
                    <div className="stat">
                        {item.l}
                    </div>
                </div>
                <div className="col-1">
                    <div className="stat">
                        {item.diff}
                    </div>
                </div>
                <div className="col-1">
                    <div className="points">
                        {item.pts}
                    </div>
                </div>
            </div>
        )
    }

    const generateFixture = (fixture: IFixture): ReactElement => {
        if (selectedGroup === 4 && !!fixture.gameId) {
            return (
                <div onClick={() => moveToMatchStatistic(fixture.gameId)} key={fixture.gameId}
                     className='col-12 col-xl-6'>
                    <div className={getTypeOfFixture(fixture)}>
                        <div className="title">
                            {moment.utc(fixture.fixture).local().format("HH:mm")}
                        </div>
                        <div className="item">
                            <span className="score">{formatPlayerScore(fixture, fixture.scorePlayerHome)}</span>
                            <span className="name">{removeNameSeparator(fixture.playerHome)}</span>
                        </div>
                        <div className="item">
                            <span className="score">{formatPlayerScore(fixture, fixture.scorePlayerAway)}</span>
                            <span className="name">{removeNameSeparator(fixture.playerAway)}</span>
                        </div>
                        <div className={getFixtureLabel(fixture).labelClass}>
                            <span className="">{getFixtureLabel(fixture).label}</span>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div onClick={() => moveToMatchStatistic(fixture.gameId)} key={fixture.gameId}
                 className="col-12 col-xl-4">
                <div className={getTypeOfFixture(fixture)}>
                    <div className="title">
                        {moment.utc(fixture.fixture).local().format("HH:mm")}
                    </div>
                    <div className="item">
                        <span className="score">{formatPlayerScore(fixture, fixture.scorePlayerHome)}</span>
                        <span className="name">{removeNameSeparator(fixture.playerHome)}</span>
                    </div>
                    <div className="item">
                        <span className="score">{formatPlayerScore(fixture, fixture.scorePlayerAway)}</span>
                        <span className="name">{removeNameSeparator(fixture.playerAway)}</span>
                    </div>
                </div>
            </div>
        )
    }

    const concatFinalFixtures = (): IFixture[] | [] => {
        const finalFixtures: IFixture[] = [];
        fixturesTables?.pageData?.mssFixturesTables?.data
            .forEach((group) => {
                if (group.isFinal) {
                    if (group.groupName === "FinalGroup1" && group.fixtures) {
                        const result = group.fixtures.map((fixture) => {
                            return {...fixture, groupName: "FinalGroup1"}
                        })
                        finalFixtures.push(...result)
                    }
                    if (group.groupName === "FinalGroup2" && group.fixtures) {
                        const result = group.fixtures.map((fixture) => {
                            return {...fixture, groupName: "FinalGroup2"}
                        })
                        finalFixtures.push(...result)
                    }
                    if (group.groupName === "SemiFinal" && group.fixtures) {
                        const result = group.fixtures.map((fixture) => {
                            return {...fixture, groupName: "SemiFinal"}
                        })
                        finalFixtures.push(...result)
                    }
                    if (group.groupName === "PlayOff3dplace" && group.fixtures) {
                        const result = group.fixtures.map((fixture) => {
                            return {...fixture, groupName: "PlayOff3dplace"}
                        })
                        finalFixtures.push(...result)
                    }
                    if (group.groupName === "Final" && group.fixtures) {
                        const result = group.fixtures.map((fixture) => {
                            return {...fixture, groupName: "Final"}
                        })
                        finalFixtures.push(...result)
                    }
                }
            })
        if (!!finalFixtures.length) {
            return finalFixtures
        } else return []
    }

    const getFixtureLabel = (fixture: IFixture): IFixtureLabel => {
        let label: IFixtureLabel = {} as IFixtureLabel;
        switch (fixture.groupName) {
            case 'FinalGroup1':
                return label = {
                    label: 'GROUP 1',
                    labelClass: 'col-5 col-sm-3 matchRank'
                }
            case 'FinalGroup2':
                return label = {
                    label: 'GROUP 2',
                    labelClass: 'col-5 col-sm-3 matchRank'
                }
            case 'SemiFinal':
                return label = {
                    label: 'SF',
                    labelClass: 'col-5 col-sm-3 matchRankSf'
                }
            case 'PlayOff3dplace':
                return label = {
                    label: 'QF',
                    labelClass: 'col-5 col-sm-3 matchRankQf'
                }
            case 'Final':
                return label = {
                    label: 'FINAL',
                    labelClass: 'col-5 col-sm-3 matchRankFinal'
                }
        }
        return label;
    }

    const getDefaultSeriesValue = () => {
        const selectedPhase = phaseOptions.find((phase) => phase.key === selectedSeries);
        if (selectedPhase) return selectedPhase.value;
        return phaseOptions[0]?.value
    };

    useEffect(() => {
        if (fixturesTables.pageHeader.currentWeekId && !selectedWeek) {
            dispatch(ActionsCreator.setSelectedSeries(fixturesTables.pageHeader.currentPhaseId))
            dispatch(ActionsCreator.setSelectedWeek(fixturesTables.pageHeader.currentWeekId))
        }
    }, [fixturesTables.pageHeader]);

    useEffect(() => {
        if (!selectedSeries && !selectedWeek) {
            dispatch(ActionsCreator.getResults());
        }
    }, [])

    useEffect(() => {
        const currentPhase = fixturesTables.pageHeader.phases.find((phase) => phase.id === fixturesTables.selectedSeries);
        if (currentPhase) {

            const currentWeek = currentPhase.weeks.find((week) => week.id === selectedWeek);
            if (currentWeek) {
                setWeek(`W ${currentWeek.sequential} - ${moment(currentWeek.from).format("MMMM Do")}`);
            }
        }
    }, [selectedWeek])

    useEffect(() => {
        if (fixturesTables.pageHeader.currentWeekId) {
            filterFixturesTables();
        }
        selectedGroup === 4 ? filterFinalTablesByGroup() : setCurrentTableGroup(1);
    }, [selectedGroup, fixturesTables.pageData.mssFixturesTables, currentTableGroup])

    return (

        <div>
            <div className="headerSpace"/>
            <div className="newsTitle">
                <div className="wrap">
                    <h1>Results & Fixtures</h1>
                </div>
            </div>
            <div className="matchStats results ">
                <div className="wrap">
                    <div className="row meta">
                        {
                            fixturesTables?.pageHeader.currentPhaseId &&
                            <div className="col-12 col-xl-6 text-center group-tabs hideDesktop">
                                <a
                                    onClick={() => setCurrentGroup(1)}
                                    className={selectedGroup === 1 ? "tab active-group" : "tab"}>
                                    Group A
                                </a>

                                <a
                                    onClick={() => setCurrentGroup(2)}
                                    className={selectedGroup === 2 ? "tab active-group" : "tab"}>
                                    Group B
                                </a>

                                <a
                                    onClick={() => setCurrentGroup(3)}
                                    className={selectedGroup === 3 ? "tab active-group" : "tab"}>
                                    Group C
                                </a>

                                <a
                                    onClick={() => setCurrentGroup(4)}
                                    className={selectedGroup === 4 ? "tab active-group" : "tab"}>
                                    Final
                                </a>
                            </div>
                        }
                        <div className="col-3 season-tabs hideMobile">

                            {selectedSeries &&
                                <Dropdown
                                    className="season-dropdown season-dropdown-phase"
                                    options={phaseOptions}
                                    onChange={changeCurrentPhase}
                                    defaultValue={getDefaultSeriesValue()}
                                />
                            }
                        </div>

                        <div className="col-3 col-sm-12 col-12 season-tabs hideDesktop">
                            <div className="mobile-seasons-tabs">
                                <div className="mobile-season-tabs">
                                    {selectedWeek && selectedSeries &&
                                        <Dropdown
                                            className="season-dropdown season-dropdown-phase"
                                            options={phaseOptions}
                                            onChange={changeCurrentPhase}
                                            defaultValue={getDefaultSeriesValue()}
                                        />
                                    }
                                </div>
                                <div className="mobile-weeks-tabs">
                                    {selectedWeek && selectedSeries &&
                                        <Dropdown
                                            className="season-dropdown"
                                            options={weekOptions}
                                            value={week}
                                            onChange={changeCurrentWeek}
                                        />
                                    }
                                </div>

                            </div>
                        </div>

                        {
                            fixturesTables?.pageHeader.currentPhaseId &&
                            <div className="col-6 text-center group-tabs hideMobile">
                                <a
                                    onClick={() => setCurrentGroup(1)}
                                    className={selectedGroup === 1 ? "tab active-group" : "tab"}>
                                    Group A
                                </a>

                                <a
                                    onClick={() => setCurrentGroup(2)}
                                    className={selectedGroup === 2 ? "tab active-group" : "tab"}>
                                    Group B
                                </a>

                                <a
                                    onClick={() => setCurrentGroup(3)}
                                    className={selectedGroup === 3 ? "tab active-group" : "tab"}>
                                    Group C
                                </a>

                                <a
                                    onClick={() => setCurrentGroup(4)}
                                    className={selectedGroup === 4 ? "tab active-group" : "tab"}>
                                    Final
                                </a>
                            </div>
                        }
                        <div className="col-3 right week-tabs hideMobile">
                            {selectedWeek && selectedSeries &&
                                <Dropdown
                                    className="season-dropdown"
                                    options={weekOptions}
                                    value={week}
                                    onChange={changeCurrentWeek}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className="fixtureBlock">
                {
                    fixturesTables?.isLoading ?
                        <Loader/>
                        :
                        <div className="wrap">
                            <div className="row">
                                <div className="col-12 col-xl-12">
                                    <div className="row">
                                        {currentFixture?.map(fixture => {
                                            return (
                                                generateFixture(fixture)
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
            {fixturesTables?.pageData?.mssFixturesTables?.weekNumber &&
                <div className="winner">
                <span
                    className="winnerTitle m-0">
                    {fixturesTables?.pageData?.mssFixturesTables.seria.toUpperCase()} - WEEK {`${fixturesTables?.pageData.mssFixturesTables.weekNumber} `}
                    WINNER
                </span>
                    <span
                        className="winnerName m-0">{fixturesTables?.pageData.mssFixturesTables.weekWinner || "TBC"}</span>
                </div>
            }

            {fixturesTables?.isLoading === false &&
                <div className="announcement tableItem hideMobile" style={{marginBottom: 0}}>
                    <img src={topBG} alt="top" className="top"/>
                    <img src={bottomBG} alt="bot" className="bottom"/>
                    {
                        (!!currentTable?.length || !!currentFinalTable?.length)
                        &&
                        <div className="wrap">
                            <div className="sub">{`2023`}</div>
                            <h3>How the table looks</h3>

                            {selectedGroup === 4 &&
                                <div className="matchStats results hideMobile">
                                    <div className="wrap">
                                        <div className="row meta">

                                            {currentFinalTable &&
                                                <div className="col-12 col-xl-12 text-center">
                                                    <a
                                                        onClick={() => setCurrentTableGroup(1)}
                                                        className={currentTableGroup === 1 ? "tab active-group" : "tab"}>
                                                        Group 1
                                                    </a>

                                                    <a
                                                        onClick={() => setCurrentTableGroup(2)}
                                                        className={currentTableGroup === 2 ? "tab active-group" : "tab"}>
                                                        Group 2
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="currentTable hideMobile">
                                {selectedGroup !== 4 &&
                                    currentTable?.map((item, index) => {
                                        return (
                                            generateTable(item, index)
                                        )
                                    })}
                                {selectedGroup === 4 &&
                                    currentFinalTable?.map((item, index) => {
                                        return (
                                            generateTable(item, index)
                                        )
                                    })}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default Results;
