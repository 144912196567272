import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";



export default interface IMatchStatistics {
    playersStatistics: {
        away: IPlayer;
        home: IPlayer;
        players: {home:string,away:string};
        playerIDs: {home:number,away:number};
        playerNames: {home:string,away:string};
        playerSurnames: {home:string,away:string};
    }
    groupName: string;
    seriesName: string;
    startDateTime: string;
    sequential?: number;
}


export interface IResultsInit {
    pageHeader: IResultsPageHeader;
    pageData: IResultsPageData;
    selectedSeries: number;
    selectedWeek: number;
    selectedGroup: number;
    isLoading: boolean;
}

export interface IResultsPageHeader {
    phases: IPhase[];
    currentWeekId: number;
    currentPhaseId: number;
}

export interface IResultsPageData {
    mssFixturesTables: IFixtureTableData
}

export interface IFixtureTableData {
    weekNumber: number;
    seria: string;
    weekWinner: string | null;
    data: IGroupResult[];
}

export interface IGroupResult {
    weekId: number;
    groupId: number;
    groupName: string;
    isFinal: boolean;
    fixturesDate: string;
    fixturesName: null;
    finalGroup: null;
    lines: ITableRow[];
    fixtures: IFixture[];
}

export interface ITableRow {
    playerId: number;
    player: string;
    p: number;
    w: number;
    l: number;
    pts: number;
    diff: number;
    legs: number;
}

export interface IFixture {
    gameId: number;
    fixture: Date;
    playerHome: string;
    playerAway: string;
    scorePlayerHome: number;
    scorePlayerAway: number;
    groupName?: string;
}

export interface IFilterFixturePayload {
    weekId: number;
    phaseId: number;
    yearId: number;
}

export default interface IFixtures{
    weekId: number;
    groupId: number;
    isFinal: boolean;
    daysFixtures: IFixture[];
}


export interface IPhase {
    id: number;
    weeks: IWeek[];
    name: string;
}

export interface IWeek {
    year: 0;
    sequential: number;
    id: number;
    number: number;
    from: Date;
    to: Date;
}

export interface IPlayer {
    totalScore: number;
    turns100to139: number;
    turns140to179: number;
    turns180: number;
    highestCheckout: number;
    checkouts100plus: number;
    average: number;
    checkoutPercentage: number;
    checkouts: string;
}

export interface IGetFilteredFixtures {
    type: ActionCreatorsTypes.GET_FILTERED_FIXTURES;
    payload: IFilterFixturePayload;
}
export interface ISetFilteredFixtures {
    type: ActionCreatorsTypes.SET_FILTERED_FIXTURES;
    payload: IResultsPageData;
}
export interface ISetSelectedSeries {
    type: ActionCreatorsTypes.SET_SELECTED_SERIES;
    payload: number;
}
export interface ISetSelectedWeek {
    type: ActionCreatorsTypes.SET_SELECTED_WEEK;
    payload: number;
}
export interface ISetSelectedGroup {
    type: ActionCreatorsTypes.SET_SELECTED_GROUP;
    payload: number;
}
export interface IGetResultsPage {
    type: ActionCreatorsTypes.GET_RESULTS_PAGE;
}
export interface ISetResultsPage {
    type: ActionCreatorsTypes.SET_RESULTS_PAGE;
    payload: IResultsInit;
}


export const defaultState: IResultsInit = {
    pageHeader: {
        phases: [
            {
                id: 0,
                name: '',
                weeks: [
                    {
                        year: 0,
                        sequential: 0,
                        id: 0,
                        number: 0,
                        from: new Date(),
                        to: new Date(),
                    }
                ]
            }
        ],
        currentWeekId: 0,
        currentPhaseId: 0,
    },
    pageData: {
        mssFixturesTables: {
            weekNumber: 0,
            seria: '',
            weekWinner: '',
            data: [
                {
                    weekId: 0,
                    groupId: 0,
                    groupName: 'string',
                    isFinal: false,
                    fixturesDate: 'string',
                    fixturesName: null,
                    finalGroup: null,
                    lines: [],
                    fixtures: [],
                }
            ],
        }
    },
    selectedSeries: 0,
    selectedWeek: 0,
    selectedGroup: 1,
    isLoading: true
};

export const playerStatsTemplate = {
    playersStatistics: {
        away: {
            totalScore: 0,
            turns100to139: 0,
            turns140to179: 0,
            turns180: 0,
            highestCheckout: 0,
            checkouts100plus: 0,
            average: 0,
            checkoutPercentage: 0,
            checkouts: ''
        },
        home: {
            totalScore: 0,
            turns100to139: 0,
            turns140to179: 0,
            turns180: 0,
            highestCheckout: 0,
            checkouts100plus: 0,
            average: 0,
            checkoutPercentage: 0,
            checkouts: ''
        },
        players: {home: '', away: ''},
        playerIDs: {home: 0, away: 0},
        playerNames: {home: '', away: ''},
        playerSurnames: {home: '', away: ''}
    },
    groupName: '',
    seriesName: '',
    startDateTime: ''
};
