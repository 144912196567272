import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import '../../assets/css/global.scss';
import '../../assets/css/animate.css';
import topSvg from '../../assets/img/announcement/top.svg';
import botSvg from '../../assets/img/announcement/bottom.svg';
import {Link} from "react-router-dom";
import {Tab, Tabs} from "react-bootstrap";
import {client} from "../../services/api.service";
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import {useDispatch} from "react-redux";
import {IMssNews} from "../../saga/mss-news/models";
import {IPhase, IWeek} from "../../saga/fixtures/models";
import {ActionsCreator} from "../../redux/actions/actionCreator";
import {useTypedSelector} from "../../hooks/useTypedSelector";


const AllNews: React.FC = () => {
    const [visibleAll, setVisibleAll] = useState(6);
    const [visibleBlogs, setVisibleBlogs] = useState(6);
    const [visibleAnnounce, setVisibleAnnounce] = useState(6);
    const [visibleReleases, setVisibleReleases] = useState(6);
    const [visibleViewMoreButtonAll, setVisibleViewMoreBtnAll] = useState(true);
    const [visibleViewMoreBtnBlog, setVisibleViewMoreBtnBlog] = useState(true);
    const [visibleViewMoreBtnAnnounce, setVisibleViewMoreBtnAnnounce] = useState(true);
    const [visibleViewMoreBtnPrRelease, setVisibleViewMoreBtnPrRelease] = useState(true);
    const [nextLiveEvent, setNextLiveEvent] = useState<string | null>(null);

    const dispatch = useDispatch();
    const {news, loaded} = useTypedSelector(state => state.news);

    const getNextSaturday = (data: { phases: IPhase[] }) => {
        let title = 'Next Live Event TBC';
        let nextSaturday, month;

        if (data.phases) {
            for (let phase of data.phases) {
                phase.weeks?.forEach((week: IWeek) => {
                        if (moment(new Date())
                            .isSameOrAfter(week.from) && moment(new Date())
                            .isSameOrBefore(week.to)) {
                            nextSaturday = Number(moment(week.from).format('D')) - 2;
                            month = moment(week.from).format('MMMM')
                        }
                    }
                )
            }
        }

        if (nextSaturday && month) {
            switch (nextSaturday) {
                case 1:
                    nextSaturday = `launch night saturday 1st ${month}`;
                    break;
                case 2:
                    nextSaturday = `launch night saturday 2nd ${month}`;
                    break;
                case 3:
                    nextSaturday = `launch night saturday 3rd ${month}`;
                    break;
                case 21:
                    nextSaturday = `launch night saturday 21st ${month}`;
                    break;
                case 22:
                    nextSaturday = `launch night saturday 22nd ${month}`;
                    break;
                case 23:
                    nextSaturday = `launch night saturday 23rd ${month}`;
                    break;
                case 31:
                    nextSaturday = `launch night saturday 31st ${month}`;
                    break;
            }

            if ((+nextSaturday >= 4 && +nextSaturday <= 20) || (+nextSaturday >= 24 && +nextSaturday <= 30)) {
                title = `launch night saturday ${nextSaturday}th ${month}`;
            }
        }
        return title
    };
    const getDartsWeeks = async () => {
        try {
            const {data} = await client.get("api/DartsTournamentPhases");
            getNextSaturday(data)
            setNextLiveEvent(getNextSaturday(data))
        } catch (e) {
            console.log(e)
        }
    };

    const getFilteredNews = (news: IMssNews[], tag: number) => {
        return news.filter((item: IMssNews) => {
            if (item.tag === tag) {
                return item
            }
        })
    }

    const getNewsLength = (tag: number) => {
        return news
            .filter((newsItem: IMssNews) => {
                return newsItem.tag === tag;
            }).length
    }

    const generateNoExistingNewsHtml = (newsType: string) => {
        return <h3 className={'noExistingNews'}>There are no existing {newsType}</h3>;
    }

    const visibilityViewMoreBtnHandler = (tag: number, visibilityState: number) => {
        const filteredNews = news
            .filter((newsItem: IMssNews) => {
            return newsItem.tag === tag
        })

        switch (tag) {
            case 1:
                return visibilityState >= filteredNews.length - 1 ? setVisibleViewMoreBtnBlog(false) : null;
            case 2:
                return visibilityState >= filteredNews.length - 1 ? setVisibleViewMoreBtnAnnounce(false) : null;
            case 3:
                return visibilityState >= filteredNews.length - 1 ? setVisibleViewMoreBtnPrRelease(false) : null;
            default:
                return visibilityState >= news.length - 1 ? setVisibleViewMoreBtnAll(false) : null;
        }
    }

    const showMoreHandler = (
        setVisibleState: React.Dispatch<React.SetStateAction<number>>,
        visibleState: number,
        e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLButtonElement;
        const typeOfNews = target.id;

        switch (typeOfNews) {
            case "allBtn":
                visibilityViewMoreBtnHandler(0, visibleState);
                break;
            case "blogsBtn":
                visibilityViewMoreBtnHandler(1, visibleState);
                break;
            case "announceBtn":
                visibilityViewMoreBtnHandler(2, visibleState);
                break;
            case "pressReleaseBtn":
                visibilityViewMoreBtnHandler(3, visibleState);

        }
        setVisibleState(visibleState + 2)
    }

    const NewsCarouselItem = (title: string, id: number, image: string, tag: number) => {
        return (
            <div className="newsItemSmall">
                <img src={`data:image/png;base64,${image}`} alt="Article Title"/>
                <div className="contents">
                    <div className="category">
                        {getNewsType(tag)}
                    </div>
                    <h3>
                        <Link to={`/singleNews/${id}`}>{title}</Link>
                    </h3>
                </div>
            </div>
        )
    }

    const getNewsType = (tag?: number) => {
        switch (tag) {
            case 1:
                return 'Blog';
            case 2:
                return 'Announcement';
            case 3:
                return 'Press Release'
        }
    }

    const getNewsIntoSlider = (sliderCount: number) => {

        return (
            <div className='swiper-container'>
                <Swiper

                    slidesPerView={sliderCount}
                    speed={600}
                    modules={[Navigation]}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                    loop
                >
                    {
                        news.map((item: IMssNews) => {
                            return <div key={item.id}>
                                <SwiperSlide key={item.id}>{NewsCarouselItem(item.title, item.id, item.images[0].image, item.tag)}</SwiperSlide>
                            </div>
                        })

                    }
                </Swiper>
                <div className="swiper-button-prev"/>
                <div className="swiper-button-next"/>
            </div>
        )
    }

    useEffect(() => {
        getDartsWeeks()
        dispatch(ActionsCreator.getNews())
    }, [])

    return (
        <>
            <div className="headerSpace"/>
            <div className="newsTitle">
                <div className="wrap">
                    <h1>Latest News</h1>
                </div>
            </div>

            {loaded ?
                <>
                    <div className="newsSlider">
                        <div className="wrap hideDesktop">
                            {getNewsIntoSlider(1)}
                        </div>

                        <div className="wrap hideMobile">
                            {getNewsIntoSlider(2.5)}
                        </div>

                    </div>
                    <div className="announcement">
                        <img src={topSvg} className="top" alt={"topSvg"}/>
                        <img src={botSvg} className="bottom" alt={"botSvg"}/>
                        <div className="wrap">
                            <div className="sub">Next Live Event</div>
                            <h3>{nextLiveEvent}</h3>
                        </div>
                    </div>
                </>
                :
                <Loader/>

            }
            {loaded &&
                <div className="extraNews">
                    <div className="wrap">
                        <Tabs
                            defaultActiveKey="all"
                            id="controlled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="all" title="All">
                                <div className="row">
                                    {
                                        loaded
                                            ?
                                            news.slice(0, visibleAll).map((item: IMssNews) => {
                                                return (
                                                    <div className="col-12 col-xl-6" key={item.id}>
                                                        {NewsCarouselItem(item.title, item.id, item.images[0].image, item.tag)}
                                                    </div>
                                                )
                                            })
                                            :
                                            generateNoExistingNewsHtml('news')
                                    }
                                </div>
                                <button
                                    id="allBtn"
                                    className={
                                        visibleViewMoreButtonAll && news?.length > 6
                                            ? "backToTop center viewMoreBtn"
                                            : "backToTop center viewMoreBtn hideMoreBtn"
                                    }
                                    onClick={(e) => showMoreHandler(setVisibleAll, visibleAll, e)}
                                >
                                    View More
                                </button>
                            </Tab>
                            <Tab eventKey="blogs" title="Blogs">
                                <div className="row">
                                    {
                                        getNewsLength(1)
                                            ?
                                            getFilteredNews(news, 1).slice(0, visibleBlogs).map((item: IMssNews) => {
                                                return (
                                                    <div className="col-12 col-xl-6" key={item.id}>
                                                        {NewsCarouselItem(item.title, item.id, item.images[0].image, item.tag)}
                                                    </div>
                                                )
                                            })
                                            :
                                            generateNoExistingNewsHtml('blogs')
                                    }
                                </div>
                                <button
                                    id="blogsBtn"
                                    className={
                                        visibleViewMoreBtnBlog && getNewsLength(1) > 6
                                            ? "backToTop center viewMoreBtn"
                                            : "backToTop center viewMoreBtn hideMoreBtn"
                                    }
                                    onClick={(e) => showMoreHandler(setVisibleBlogs, visibleBlogs, e)}
                                >
                                    View More
                                </button>
                            </Tab>
                            <Tab eventKey="announcements" title="Announcements">
                                <div className="row">
                                    {
                                        getNewsLength(2)
                                            ?
                                            getFilteredNews(news, 2).slice(0, visibleAnnounce).map((item: IMssNews) => {
                                                return (
                                                    <div className="col-12 col-xl-6" key={item.id}>
                                                        {NewsCarouselItem(item.title, item.id, item.images[0].image, item.tag)}
                                                    </div>
                                                )
                                            })
                                            :
                                            generateNoExistingNewsHtml('announcements')
                                    }
                                </div>
                                <button
                                    id="announceBtn"
                                    className={
                                        visibleViewMoreBtnAnnounce && getNewsLength(2)
                                            ? "backToTop center viewMoreBtn"
                                            : "backToTop center viewMoreBtn hideMoreBtn"
                                    }
                                    onClick={(e) => showMoreHandler(setVisibleAnnounce, visibleAnnounce, e)}
                                >
                                    View More
                                </button>
                            </Tab>
                            <Tab eventKey="press-releases" title="press releases">
                                <div className="row">
                                    {
                                        getNewsLength(3)
                                            ?
                                            getFilteredNews(news, 3).slice(0, visibleReleases).map((item: IMssNews) => {
                                                return (
                                                    <div className="col-12 col-xl-6" key={item.id}>
                                                        {NewsCarouselItem(item.title, item.id, item.images[0].image, item.tag)}
                                                    </div>
                                                )
                                            })
                                            :
                                            generateNoExistingNewsHtml('press releases')
                                    }
                                </div>
                                <button
                                    id="pressReleaseBtn"
                                    className={
                                        visibleViewMoreBtnPrRelease && getNewsLength(3) > 6
                                            ? "backToTop center viewMoreBtn"
                                            : "backToTop center viewMoreBtn hideMoreBtn"
                                    }
                                    onClick={(e) => showMoreHandler(setVisibleReleases, visibleReleases, e)}
                                >
                                    View More
                                </button>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            }
        </>
    )
}

export default AllNews;
