import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";
import {IGetNews, IMssNews, INewsLoader, ISetNews} from "../../saga/mss-news/models";
import {
    IGetResultsPage,
    ISetResultsPage,
    IResultsInit,
    ISetSelectedGroup,
    ISetSelectedSeries,
    ISetSelectedWeek, IFilterFixturePayload, IGetFilteredFixtures, ISetFilteredFixtures, IResultsPageData
} from "../../saga/fixtures/models";

export const ActionsCreator = {
    getNews: (): IGetNews => (
        {type: ActionCreatorsTypes.GET_NEWS}),
    setNews: (payload: IMssNews): ISetNews => (
        {type: ActionCreatorsTypes.SET_NEWS, payload}),
    loaderNews: (payload: boolean): INewsLoader => (
        {type: ActionCreatorsTypes.NEWS_LOADER, payload}),
    getResults: (): IGetResultsPage => (
        {type: ActionCreatorsTypes.GET_RESULTS_PAGE}),
    setResults: (payload: IResultsInit): ISetResultsPage => (
        {type: ActionCreatorsTypes.SET_RESULTS_PAGE, payload}),
    getFilteredFixtures: (payload: IFilterFixturePayload): IGetFilteredFixtures => (
        {type: ActionCreatorsTypes.GET_FILTERED_FIXTURES, payload}),
    setFilteredFixtures: (payload: IResultsPageData): ISetFilteredFixtures => (
        {type: ActionCreatorsTypes.SET_FILTERED_FIXTURES, payload}),
    setSelectedSeries: (payload: number): ISetSelectedSeries => (
        {type: ActionCreatorsTypes.SET_SELECTED_SERIES, payload}),
    setSelectedWeek: (payload: number): ISetSelectedWeek => (
        {type: ActionCreatorsTypes.SET_SELECTED_WEEK, payload}),
    setSelectedGroup: (payload: number): ISetSelectedGroup => (
        {type: ActionCreatorsTypes.SET_SELECTED_GROUP, payload}),
}
