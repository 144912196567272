import React, {useEffect, useState} from 'react';
import '../../assets/css/global.scss';
import {useLocation} from "react-router-dom";
import {SingleNewsItem} from "./SingleNewsItem";
import {client} from "../../services/api.service";
import Loader from "../../components/Loader/Loader";
import {INewsFetchData} from "../../saga/mss-news/models";


const NewsSingleOne: React.FC = () => {
    const [newsData, setNewsData] = useState<INewsFetchData>({} as INewsFetchData);
    const [newsId, setNewsId] = useState<number>(0);
    const urlPath = useLocation();
    const getPathId = (url: string) => +url.split('/')[2];

    const fetchNewsData = async (id: number) => {
        try {
            setNewsData({} as INewsFetchData)
            const resp = await client.get(`/api/MssWeb/news/${id}`);
            setNewsData(resp.data)
        } catch (e: unknown) {
            if (e instanceof Error) {
                console.log('fetchNewsData:', e.message)
            }
        }
    }

    useEffect(() => {
        setNewsId(getPathId(urlPath.pathname))
    }, [urlPath.pathname])

    useEffect(() => {
        if (!!newsId) fetchNewsData(newsId)
    }, [newsId])

    const render = (id: number) => {
        {
            return newsData?.singleNews?.id
                ?
                <SingleNewsItem title={newsData?.singleNews?.title}
                                subtitle={newsData?.singleNews?.subtitle}
                                id={newsData?.singleNews?.id}
                                body={newsData?.singleNews?.text}
                                image={newsData?.singleNews?.images[0]?.image}
                                date={newsData?.singleNews?.newsDate}
                                lastThreeNews={newsData?.lastThreeNews}/>
                :
                <Loader/>
        }
    }

    return (
        <div>
            {render(getPathId(urlPath.pathname))}
        </div>
    )
}
export default NewsSingleOne;
