import React from 'react';
import '../../assets/css/global.scss';
import '../../assets/css/animate.css';
import topSvg from '../../assets/img/announcement/top.svg';
import botSvg from '../../assets/img/announcement/bottom.svg';

const HomePage: React.FC = () => {
    return (
        <>
            <div className="headerSpace"></div>
            <div className="newsTitle about">
                <div className="wrap">
                    <h1 style={{maxWidth: '900px'}}>ABOUT MODUS <span>SUPER SERIES</span></h1>
                </div>
            </div>


            <div className="announcement about" style={{overflow: 'visible', marginBottom: 0, marginTop: '140px'}}>
                <img src={topSvg} className="top"/>
                <img src={botSvg} className="bottom hideMobile"/>
                <div className="wrap">
                    <div className="vidEmbed">
                        <div className="video-container">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/HMCq1sJuJGM"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>

                            </iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div className="announcement black hideMobile" style={{margin: "0"}}>
                <div className="wrap">
                    <div className="sub">What do we do?</div>
                    <h3>WE LIVESTREAM 105 GAMES OF DARTS WEEKLY TO A GLOBAL AUDIENCE.<br/>WATCH LIVE ON SPORTY STUFF TV
                    </h3>
                </div>
            </div>

            <div className="announcement about2 hideDesktop" style={{margin: "0", paddingTop: "0"}}>
                <img src={botSvg} className="bottom"/>


                <div className="wrap">
                    <div className="sub">What do we do?</div>
                    <h3>WE LIVESTREAM 105 GAMES OF DARTS WEEKLY TO A GLOBAL AUDIENCE.<br/>WATCH LIVE ON SPORTY STUFF TV</h3>
                </div>
            </div>

            <div className="rules">
                <img src={topSvg} className="top hideMobile"/>
                <img src={botSvg} className="bottom hideMobile"/>

                <div className="wrap">
                    <div className="row">
                        <div className="col-12 col-xl-6 mainContent">
                            <div className="subTitle">
                                Rules
                            </div>

                            <p className="rules-subtitle">
                                <strong>
                                    The MODUS SUPER SERIES is a weekly tournament featuring twelve players,
                                    competing from MONDAY to Friday to win a place in the finals group which is
                                    staged on Saturday evening.
                                </strong>
                            </p>

                            <p>
                                The twelve players are split into three groups, with six players going into Group A,
                                and three going into Groups B & C.
                            </p>

                            <p>
                                Play commences in Group A on a Monday, Tuesday and Wednesday starting at 9:30 each day.
                                The player who finishes first after three sessions qualifies directly
                                for finals night on Saturday evening.
                            </p>

                            <p>
                                Group B plays on a Thursday and Friday evening from 22:00 and Group C plays on a
                                Thursday and Friday during the day from 9:30. The top three players from Group B and
                                the top two players from Group C qualify for finals night, the rest of the
                                players are eliminated.
                            </p>

                            <p>
                                Finals night is played on Saturday evening starting at 22:00 where
                                the players compete in a small group stage followed by semi-finals
                                and a final to determine the weekly winner.
                            </p>
                            <p>
                                The competition then starts again the following week, with twelve new
                                players competing for the title.
                            </p>
                            <p>
                                After twelve weeks of competition, the twelve weekly winners compete in ‘Champions Week’
                                which is played every thirteenth game week to decide who will win the Series and earn
                                a place on the honours board.
                            </p>

                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="row">
                                <div className="col-12 col-xl-6 wow fadeIn">
                                    <div className="rule">
                                        <div className="number">
                                            12
                                        </div>
                                        <div className="contents">
                                            12 players are split into three groups, with 6 going into group A,
                                            and 3 each in groups B and C
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 wow fadeIn" data-wow-delay="0.4s">
                                    <div className="rule">
                                        <div className="number">
                                            7
                                        </div>
                                        <div className="contents">
                                            A best of 7 Legs are played in a round robin format (501).
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 wow fadeIn" data-wow-delay="0.8s">
                                    <div className="rule">
                                        <div className="number">
                                            6
                                        </div>
                                        <div className="contents">
                                            Players progress to the finals on Saturday whilst the rest are eliminated
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 wow fadeIn" data-wow-delay="1.2s">
                                    <div className="rule">
                                        <div className="number">
                                            12
                                        </div>
                                        <div className="contents">
                                            Weeks of gameplay to find qualifying players for Champions Week.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="history">

                <img src="https://i.gyazo.com/38488dbbdee915d155440ca4c2045d79.png" className="hideDesktop"
                     style={{marginTop: "-50px", marginBottom: "30px", width: "100%"}}/>

                <div className="wrap">
                    <div className="sub">
                        Made by Modus
                    </div>
                    <h3>The history of MSS</h3>

                    <p className="intro">
                        The MODUS Super Series is a successor to the Online Darts Live League which launched in 2021.
                        This had grown from a COVID-era, play-at-home darts tournament to a made-for-TV, studio based
                        competition which ran for two years
                    </p>

                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <p>The MODUS Super Series is the next step in the evolution of this competition, launching
                                in September 2022 in a top-spec, purpose built location in Portsmouth, UK.
                            </p>

                            <p>
                                The MODUS Super Series is open to non-PDC (Professional Darts Corporation)
                                Tour Card holders and legends from the sport, with Fallon Sherrock,
                                Martin Adams, Robert Thornton, Kevin Painter, Mikuru Suzuki and Thibault Tricole among
                                the most well-known names. The roster of commentators and presenters features
                                Paul Nicholson, Mark Webster, Chris Mason, Chris Murphy and Abigail Davies.
                            </p>

                            <p>
                                The competition is organised by MODUS, an events and management company that represents
                                many of the world’s top professional darts players including Michael van Gerwen, Fallon
                                Sherrock, Simon Whitlock, Joe Cullen and many more of the sport’s leading names.
                            </p>
                            <p>
                                Founded in 1997, MODUS has decades of experience hosting premier darts events and exhibitions.
                            </p>

                            <a href="https://modusdarts.tv/" className="cta">
                                Visit Modus' Website
                            </a>

                        </div>
                        <div className="col-12 col-xl-1"/>
                        <div className="col-12 col-xl-5 hideMobile">
                            <img src="https://i.gyazo.com/38488dbbdee915d155440ca4c2045d79.png" width="100%"/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}

export default HomePage;
