import {client} from "../../services/api.service";
import IFixtures, {IFilterFixturePayload, IResultsInit} from "./models";
import IMatchStatistics from "./models";

export const getFixturesTablesRequest = async (): Promise<IResultsInit> => {
    const results = await client.get(`api/MssWeb/results-init`);
    return results.data;
}

export const getMatchStatistic = async (gameId: number) : Promise<IMatchStatistics> => {
    const response = await client.get( `/api/MssWeb/fixtures/${gameId}`);
    return response.data;
}

export const fixtureFilterRequest = async (payload: IFilterFixturePayload): Promise<IFixtures> => {
    const response = await client.get(`/api/MssWeb/results-data?WeekId=${payload.weekId}&PhaseId=${payload.phaseId}&Year=${payload.yearId}`);
    return response.data;
}
